import './header.sass'
import { computed, defineComponent, inject, Ref, ref, Transition, watch } from 'vue'
import { ListItem } from '@/core/types'
import { useToggle } from '@/composables/use-toggle'
import UserPanel from './user-panel'

class LinkItem extends ListItem {
  icon: string = ''
}

const defaultLinks: LinkItem[] = [
  { name: 'Мои деньги', value: 'https://profinansy.ru/capital', icon: 'menu-capital-icon.svg' },
  { name: 'Мои расходы', value: '', icon: 'menu-expenses-icon.svg' },
  { name: 'Инвестиции', value: 'https://profinansy.ru/landings/investment', icon: 'menu-investments-icon.svg' },
  { name: 'Обучение', value: 'https://profinansy.ru/courses', icon: 'menu-investments-icon.svg' },
  { name: 'Тарифы', value: ' https://profinansy.ru/payment?utm_source=landing&utm_medium=platforma', icon: 'menu-investments-icon.svg' },
  { name: 'О проекте', value: 'https://profinansy.ru/about', icon: 'menu-investments-icon.svg' }
  // { name: 'Лента', value: 'https://profinansy.ru/feed', icon: 'menu-investments-icon.svg' }
]

const isPaidLinks: LinkItem[] = [
  { name: 'Мои деньги', value: 'https://profinansy.ru/capital', icon: 'menu-capital-icon.svg' },
  { name: 'Мои расходы', value: '', icon: 'menu-expenses-icon.svg' },
  { name: 'Инвестиции', value: 'https://profinansy.ru/market/dashboard ', icon: 'menu-investments-icon.svg' },
  { name: 'Обучение', value: 'https://profinansy.ru/courses', icon: 'menu-investments-icon.svg' },
  { name: 'Поиск', value: 'https://profinansy.ru/market/screener', icon: 'menu-investments-icon.svg' },
  { name: 'Клуб', value: 'https://profinansy.ru/club', icon: 'menu-investments-icon.svg' }
  // { name: 'Лента', value: 'https://profinansy.ru/feed', icon: 'menu-investments-icon.svg' }
]

const defaultUserIcon = () => <svg class="MuiSvgIcon-root MuiAvatar-fallback" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>

export default defineComponent({
  setup () {
    const userAvatar: Ref<string | null> = inject('userAvatar', ref(null))
    const isPaid: Ref<boolean> = inject('isPaid', ref(false))
    const isOpen: Ref<boolean> = ref(false)
    const mobileMenu = ref(null) as unknown as Ref<HTMLElement>
    const isOpenUserPanel: Ref<boolean> = ref(false)
    let touchStart = 0

    const links = computed(() => {
      if (isPaid.value) return isPaidLinks
      else return defaultLinks
    })

    watch(isOpen, to => {
      const body = document.querySelector('body')
      if (!body) return
      if (to) body.style.overflow = 'hidden'
      else body.style.overflow = 'auto'
    })

    const onTouchStart = (e: TouchEvent) => {
      touchStart = e.changedTouches[0].screenX
    }

    const onTouchEnd = (e: TouchEvent) => {
      const { screenX } = e.changedTouches[0]
      if (screenX < touchStart) isOpen.value = false
    }

    const onClickSelf = (e: Event) => {
      if ((e.target as HTMLElement).classList.value === 'header-mobile-menu-wrapper') isOpen.value = false
    }

    return () => <header class='header' style={{ boxShadow: '0 0 0 1px rgb(26 26 26 / 10%), 0 0 0 rgb(26 26 26 / 0%), 0 1px 1px rgb(26 26 26 / 20%)' }}>
      {isOpen.value && <div class='header-menu-wrapper' onClick={onClickSelf}>
        <div class='header-mobile-menu-wrapper' onTouchstart={onTouchStart} onTouchend={onTouchEnd}>
          <Transition
            appear
            duration={-10000}
            enterToClass='menu-enter'
            enterActiveClass='menu-enter'
            leaveToClass='menu-out'
            leaveActiveClass='menu-out'
          >
            <div class='header-mobile-menu' ref={mobileMenu}>
              <div class='header-mobile-menu-head flex-sb'>
                <div class='flex'>
                  <img class='mr-1' src={require('@/assets/img/circle-logo.svg')} alt='' />
                  <img src={require('@/assets/img/second-logo.svg')} alt='' />
                </div>
              </div>
              <nav class={{ 'header-mobile-menu-nav': true }}>
                { links.value.map(link => <div>
                  {!link.value && <div class='flex header-mobile-menu-nav-item active'>
                    <div class='flex-center header-mobile-menu-icon'>
                      <img src={require(`@/assets/img/${link.icon}`)} alt='' />
                    </div>
                    <span>{link.name}</span>
                  </div>}
                  {link.value && <a style={link.name === 'Мои расходы' ? { color: '#6553F5', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }} href={process.env.VUE_APP_AUTH_SERVICE + link.value} class='flex header-mobile-menu-nav-item'>
                    <div class='flex-center header-mobile-menu-icon'>
                      <img src={require(`@/assets/img/${link.icon}`)} alt='' />
                    </div>
                    <span>{link.name}</span>
                  </a>}
                </div>) }
              </nav>
            </div>
          </Transition>
        </div>
      </div>}
      <div class='header-wrapper'>
        <div class='flex-sb' style={{ height: '100%' }}>
          <button class='header-burger-button' onClick={() => useToggle(isOpen)}>
            <img src={require('@/assets/img/burger.svg')} alt='' />
          </button>
          <div class='header-left flex'>
            <a class='header-logo' href='/about'>
              <img src={require('@/assets/img/logo-prod.svg')} alt=''/>
            </a>
            <nav class={{ 'header-nav flex': true, paid: isPaid.value }}>
              { links.value.map(link => <div>
                {link.value ? <a style={link.name === 'Мои расходы' ? { color: '#6553F5', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }} href={link.value}>{ link.name }</a> : <span style={link.name === 'Мои расходы' ? { color: '#6553F5', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}>{link.name}</span>}
              </div>) }
            </nav>
          </div>
          <div class='header-right flex-center'>
            {/* <div class='header-notifications'>
              <img src={require('@/assets/img/notifications.svg')} alt=''/>
            </div> */}
            <div class='header-user-block'>
              <div class='header-user ml-2' onClick={() => useToggle(isOpenUserPanel)} style={userAvatar.value ? `background: url(${userAvatar.value}) center; background-size: cover; background-position: center;` : ''}>
                { !userAvatar.value && <div>{ defaultUserIcon() }</div> }
              </div>
              {isOpenUserPanel.value &&
                <Transition
                  appear
                  duration={-10000}
                  enterToClass='menu-enter'
                  enterActiveClass='menu-enter'
                  leaveToClass='menu-out'
                  leaveActiveClass='menu-out'
                >
                  <UserPanel onClose={() => { isOpenUserPanel.value = false }}/>
                </Transition>
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  }
})
